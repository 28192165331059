import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import validation from 'validate.js';
import Api from '../../logic/api/Api';
// import LoadingOverlay from '../../components/LoadingOverlay';

export default class FormHandler {
  /**
   * Constructor
   *
   * @param component
   * @param startingData
   */
  constructor(component, model, customState = {}, options = {}) {
    this.model = model;
    this.component = component;
    this.callbacks = {
      submitError: [],
      submitSuccess: []
    };

    const startingData = model.getData();

    // Set default component state
    component.state = {
      isLoading: false,
      submitStatus: false,
      record: startingData,
      errors: [],
      ...customState
    };

    // Rewire componentWillUpdate
    const oldWillUpdate = component.componentWillUpdate;
    component.componentWillUpdate = (nextProps, nextState) => {
      this.state = nextState;
      if (typeof oldWillUpdate === 'function') {
        oldWillUpdate(nextProps, nextState);
      }
    };

    // Store state here
    this.state = component.state;

    // Map setState from component
    this.setState = (param, callback = null) => {
      component.setState(param, () => {
        this.state = component.state;
        if (callback) callback();
      });
    };

    this.options = options;
  }

  /**
   * Get submit url
   *
   * @returns {null}
   */
  getSubmitUrl() {
    if (this.component.getSubmitUrl) {
      return this.component.getSubmitUrl(this.state.record);
    }
    return this.model.getSubmitUrl(this.state.record);
  }

  getSubmitMethod() {
    if (this.component.getSubmitMethod) {
      return this.component.getSubmitMethod(this.state.record);
    }
    return this.model.getSubmitMethod(this.state.record);
  }

  /**
   * Handle submit
   *
   * @param event
   * @returns {boolean}
   */
  handleSubmit(event = null, shouldReject = false) {
    return new Promise((resolve, reject) => {
      if (event) {
        event.preventDefault();
      }

      // Clear errors
      this.clearErrors();

      // Client side validation
      if (!this.clientValidation()) {
        this.scrollToError();
        return;
      }

      
      // On before submit
      if (this.onBeforeSubmit() === false) {
        reject();
        return;
      }
      this.onBeforeSubmit();
      
      // return if there is no submitUrl
      if (!this.getSubmitUrl()) {
        return;
      }
      // Call api to login
      Api.call(
        this.getSubmitUrl(),
        this.getSubmitMethod(),
        this.getSubmitData()
      )
        .then(
          data => {
            this.onSubmitSuccess(data);
            resolve(data);
          },
          error => {
            this.onSubmitError(error);
            if (shouldReject) {
              reject(error);
            }
          }
        )
        .finally(() => {
          this.onAfterSubmit();
        });
    });
  }

  /**
   * Called before submit
   */
  onBeforeSubmit() {
    // Set loading
    this.setState({
      isLoading: true
    });
  }

  /**
   * Called after submit
   */
  onAfterSubmit() {
    this.setState({
      isLoading: false
    });
  }

  /**
   * Returns submit data to send over AJAX
   *
   * @returns {{}|record|*}
   */
  getSubmitData() {
    if (this.component.getSubmitData) {
      return this.component.getSubmitData(this.state.record);
    }

    return this.state.record;
  }

  /**
   * Called on ajax success
   *
   * @param data
   */
  onSubmitSuccess(data) {
    // Parse response
    let submitStatus = false;
    if (data.success) {
      submitStatus = true;
      if (data.data && data.data.redirect) {
        window.location = data.data.redirect;
      }
    }
    // Set state
    this.setState({
      submitStatus,
      submitMessage: data.message
    });

    if (data.success) {
      this.callback('submitSuccess', data);
      if (this.component.onSubmitSuccess) {
        this.component.onSubmitSuccess(data);
      }
    }

    if (!data.success) {
      this.callback('submitError', data);
      if (this.component.onSubmitError) {
        this.component.onSubmitError(data);
      }

      this.handleServerErrors(data);
    }

    // Clear state
    setTimeout(() => {
      this.setState({
        submitMessage: null
      });
    }, 3000);
  }

  /**
   * Called on submit error
   *
   * @param error
   */
  onSubmitError(error) {
    // console.error('error', error);
    if (error.errors && error.errors.length) {
      this.setState({
        submitStatus: false,
        submitMessage: error.errors[0].message
      });
    }

    if (this.component.onSubmitError) {
      this.component.onSubmitError(error);
    }
    this.callback('submitError', error);
  }

  /**
   * Handle input change
   *
   * @param event
   */
  handleInputChange(event, passedValue = null) {
    let name = null;
    let value = null;
    if (!event.target) {
      name = event;
      value = passedValue;
    } else {
      name = event.target.name;
      value = event.target.value;
    }
    this.setState(
      {
        ...this.state,
        // Intentionally using lodash's _.set here to support nested properties
        record: _.set({ ...this.state.record }, name, value)
      },
      () => {
        this.callback('inputChange', { name, value });
      }
    );

    if (passedValue !== '') {
      this.clearErrors();
    }
  }

  /**
   * Get submission status component
   *
   * @returns {string}
   */
  getSubmissionStatus() {
    let submissionStatus = '';

    if (this.state.submitMessage) {
      submissionStatus = (
        <div className="form-message">{this.state.submitMessage}</div>
      );
    }

    return submissionStatus;
  }

  /**
   * Get form class component
   *
   * @returns {string}
   */
  getFormClass() {
    let formClass = '';
    if (typeof this.state.submitStatus !== 'undefined') {
      formClass = this.state.submitStatus ? '' : 'form-error';
    }

    return formClass;
  }

  /**
   * Get loading component
   *
   * @returns {string}
   */
  // getLoadingComponent() {
  //   let loadingComponent = '';
  //   if (this.state.isLoading) {
  //     loadingComponent = <LoadingOverlay />;
  //   }

  //   return loadingComponent;
  // }

  /**
   * Invalidate field
   *
   * @param field
   * @param message
   */
  invalidateField(field, message) {
    const errors = this.state.errors || [];

    errors.push({
      field,
      message
    });

    this.setState({
      errors
    });
  }

  clearErrors() {
    this.setState({
      errors: []
    });
    this.state.errors = [];
  }

  getErrors() {
    return this.state.errors;
  }

  validate() {
    this.clearErrors();
    return this.clientValidation();
  }

  clientValidation() {
    const validationRules = this.model.getValidationRules(this.state.record);

    const data = { ...this.state.record };

    for (const k in data) {
      const value = data[k];
      if (typeof value === 'string' && value.trim() === '') {
        data[k] = null;
      }
    }

    const errors = validation.validate(data, validationRules, {
      fullMessages: false
    });

    for (const field in errors) {
      this.invalidateField(field, errors[field]);
    }

    const customErrors = this.model.customValidation(this.state.record);

    if (customErrors.length) {
      customErrors.forEach(error => {
        this.invalidateField(error.field, error.message);
      });
    }

    return this.getErrors().length === 0;
  }

  scrollToError() {
    setTimeout(() => {
      if ($('.validation-message').length) {
        const firstError = $('.validation-message').first();
        if (firstError && firstError.offset()) {
          $('html, body').animate(
            {
              scrollTop: firstError.offset().top - 200
            },
            500
          );
        }
      }
    }, 300);
  }

  on(callbackName, callbackFunction) {
    if (!this.callbacks[callbackName]) {
      this.callbacks[callbackName] = [];
    }

    this.callbacks[callbackName].push(callbackFunction);
  }

  callback(callbackName, data) {
    if (!this.callbacks[callbackName]) return;

    const callbacks = this.callbacks[callbackName];
    for (let i = 0; i < callbacks.length; i++) {
      try {
        callbacks[i](data);
      } catch (e) {
        console.error(e);
      }
    }
  }

  handleServerErrors(data) {
    if (!data || !data.errors || !data.errors.length) return;

    data.errors.forEach(error => {
      if (error.type === 'validation_error') {
        this.invalidateField(error.field, error.message);
      }
    });
  }
}
