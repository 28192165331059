import React from 'react';
import { Link } from 'react-router-dom';
import AppTable from '@launchpad/components/crud/AppTable';
import UserModel from '@baseProject/configuration/modules/users/models/UsersModel';

const UserTierUsers = props => {
  return (
    <AppTable
      icon="user"
      title="Users"
      params={{
        userTierId: props.userTier.id
      }}
      filter={[
        {
          name: 'term',
          type: 'term'
        }
      ]}
      getTableColumns={() => [
        {
          name: 'id',
          label: 'Id',
          sortable: false,
          type: 'field'
        },
        {
          name: 'firstName',
          label: 'First name',
          sortable: false,
          type: 'field'
        },
        {
          name: 'lastName',
          label: 'Last name',
          sortable: false,
          type: 'field'
        },
        {
          name: 'email',
          label: 'E-mail',
          sortable: false,
          type: 'field'
        },
        {
          name: null,
          label: 'Actions',
          sortable: false,
          type: 'actions'
        }
      ]}
      model={UserModel}
      getColumnActions={member => {
        return (
          <div>
            <Link to={`/user-details/${member.id}`} className="button-info">
              Details
            </Link>
          </div>
        );
      }}
    />
  );
};

export default UserTierUsers;
