import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class PendingAuthorizationsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/auth/authorization/pending';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/auth/authorization/challenge';
  }

  async delete(authorizationId) {
    const result = await Api.call('admin/auth/authorization/reject', 'PATCH', {
      authorizationId,
    });
    return result;
  }

  /**
   * Get submit url
   */
  async submitAuthorization(id) {
    const result = await Api.call(
      'admin/auth/authorization/challenge',
      'PATCH',
      { authorizationId: id }
    );
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'creatorId',
        label: 'Creator',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: 'additionalData.fromAccountId',
        label: 'From Account ID',
        sortable: false,
        type: 'field',
      },
      {
        name: 'additionalData.beneficiary.accountNumber',
        label: 'To Account Number',
        sortable: false,
        type: 'field',
      },
      {
        name: 'additionalData.amount',
        label: 'Amount',
        sortable: false,
        type: 'field',
      },
      {
        name: 'createdAt',
        label: 'Created At',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }
}
