/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import ToastHelper from '@launchpad/util/ToastHelper';
import { connect } from 'react-redux';
import AppSelectWithSearch from '@launchpad/components/crud/AppSelectWithSearch';
import {
  FormHandler,
  FormSubmitWrapper,
  InputField,
  ModalDialog,
  AppCheckboxInput,
} from '../../../index';
import { getCurrencySymbol } from '../../../util/CurrencyHelper';
import OutboundPaymentModel from '../models/OutboundPaymentModel';

// const PAYMENT_TYPES = [
//   'payment-type-Global-SWIFT',
//   'payment-type-EU-SEPA-Instant',
//   'payment-type-UK-BACS',
//   'payment-type-US-ACH',
//   'payment-type-EU-SEPA-Target2',
//   'payment-type-UK-Chaps',
//   'payment-type-card',
//   'payment-type-EU-SEPA-Step2',
//   'payment-type-SG-FAST',
//   'payment-type-virtual-debit-card-debit',
//   'payment-type-inter-ledger',
//   'payment-type-UK-FasterPayments',
// ];

class OutboundPaymentModal extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new OutboundPaymentModel({
        fromAccountId: this.props.account?.id,
        paymentType: 'payment-type-UK-FasterPayments',
        amount: '',
        reference: '',
        currency: this.props.account.currency ?? 'GBP',
        saveAsBeneficiary: false,
        beneficiary: {
          name: '',
          accountNumber: '',
          sortCode: '',
        },
      })
    );
  }

  onSubmitError(error) {
    // if error.code === 422 consider request as successful
    if (error.code === 422) {
      ToastHelper.show(
        'Your request has been successfully created.',
        'success'
      );
    } else {
      ToastHelper.show('Something went wrong, please try again later.');
    }
    this.props.onClose();
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return 'Outbound payment successfully finished!';
    }
    return null;
  }

  render() {
    const { formHandler } = this;

    return (
      <ModalDialog
        show={this.props.showModal}
        onClose={() => this.props.onClose()}
      >
        <div>
          <h3>Outbound payment</h3>
          <div
            className="element-with-blur"
            style={{ paddingTop: '2px', marginRight: 20 }}
          >
            <div className="add-new-group" style={{ position: 'relative' }}>
              <div className="container">
                <FormSubmitWrapper
                  formHandler={formHandler}
                  to={this.props.redirectRoute}
                  buttonText="Initiate payment"
                  customCloseFunction={this.props.onClose}
                  getModalMessage={(data) => this._getModalMessage(data)}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <AppSelectWithSearch
                        url="admin/dnd/beneficiary"
                        label="Select beneficiary"
                        name="beneficiary"
                        value={this.state.record.beneficiary}
                        handler={formHandler}
                        placeholder="Search a beneficiary"
                        wrapperStyle={{ zIndex: 99999 }}
                        getOptionValue={(user) => user}
                        getOptionLabel={(user) =>
                          user.id
                            ? `${user.name} (${user.accountNumber})`
                            : '- any -'
                        }
                        noOptionsMessage={() => <span style={{color: 'black'}}>No beneficiaries</span>}
                        isClearable
                        onChange={(item) => {
                          if (!item) {
                            this.setState((prevState) => ({
                              ...prevState,
                              record: {
                                ...prevState.record,
                                beneficiary: {
                                  name: '',
                                  accountNumber: '',
                                  sortCode: '',
                                },
                              },
                            }));
                          } else {
                            this.setState((prevState) => ({
                              ...prevState,
                              record: {
                                ...prevState.record,
                                beneficiary: {
                                  ...item,
                                },
                              },
                            }));
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="From account"
                        type="text"
                        name="fromAccountId"
                        value={this.props.account?.iban || this.state.record.fromAccountId}
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Account number"
                        type="text"
                        name="beneficiary.accountNumber"
                        value={this.state.record.beneficiary.accountNumber}
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Reference"
                        type="text"
                        name="reference"
                        value={this.state.record.reference}
                        handler={formHandler}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Beneficiary name"
                        type="text"
                        name="beneficiary.name"
                        value={this.state.record.beneficiary.name}
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Sort code"
                        type="text"
                        name="beneficiary.sortCode"
                        value={this.state.record.beneficiary.sortCode}
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                          inputProps: { min: 0, step: "any" },
                        }}
                        type="number"
                        label="Amount*"
                        name="amount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.record.amount}
                        handler={formHandler}
                        prefix={getCurrencySymbol(this.state.record.currency)}
                      />
                    </div>
                    {!this.state.record.beneficiary.id && (
                      <div className="col-sm-12">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                          }}
                        >
                          <AppCheckboxInput
                            style={{ marginTop: 20 }}
                            name="saveAsBeneficiary"
                            id="saveAsBeneficiary"
                            htmlFor="saveAsBeneficiary"
                            labelText="Save as beneficiary"
                            onChange={(value) =>
                              this.formHandler.handleInputChange(
                                'saveAsBeneficiary',
                                value
                              )
                            }
                            value={this.state.record.saveAsBeneficiary}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps, null)(OutboundPaymentModal);

OutboundPaymentModal.defaultProps = {
  entityId: null,
  entity: null,
};
