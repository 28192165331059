import AppTable from '@launchpad/components/crud/AppTable';
import { currency } from '@launchpad/util/NumberHelper';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OutboundPaymentModel from '../models/OutboundPaymentModel';
import OutboundPaymentModal from '../modals/OutboundPaymentModal';

export default class MoneyMovementOutboundPayment extends Component {
  _tableComponent;

  constructor(props) {
    super(props);
    this.state = { showModal: false };

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Outbound payment',
        link: '/',
      },
    ]);
  }

  render() {
    const inner = (
      <>
        <AppTable
          icon="address-card"
          title="Outbound payment"
          onInit={(tableComponent) => (this._tableComponent = tableComponent)}
          params={{
            type: 'INTERNAL',
          }}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'userUuid':
                return (
                  <Link
                    className="userCardLink"
                    to={`/user-details/${item.userUuid}`}
                  >
                    {item.user
                      ? `${item.user.firstName} ${item.user.lastName}`
                      : 'User Details'}
                  </Link>
                );
              case 'availableBalance':
                return currency(
                  item.availableBalance ? item.availableBalance : item.balance,
                  item.currency
                );
              default:
            }
          }}
          model={OutboundPaymentModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={{
                    pathname: `/outbound-payment/transactions/${member.id}`,
                    state: { item: member },
                  }}
                  className="button-info"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  Transactions
                </Link>
                <a
                  href="javascript:void(0)"
                  className="button-info"
                  onClick={() => this.setState({ showModal: member.id })}
                >
                  Outbound payment
                </a>
                {this.state.showModal === member.id && (
                  <OutboundPaymentModal
                    account={member}
                    showModal={this.state.showModal === member.id}
                    onClose={() => this.setState({ showModal: false })}
                  />
                )}
              </div>
            );
          }}
        />
      </>
    );

    if (this.props.justTable) return inner;

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-share-alt" aria-hidden="true" /> Money movement
        </h1>
        {inner}
      </div>
    );
  }
}
