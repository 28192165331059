import React, { Component } from 'react';
import Api from '@launchpad/logic/api/Api';
import { Redirect } from 'react-router-dom';
import { LoadingOverlay } from '@launchpad';
import { date } from '../../../../../../@launchpad/util/DateHelper';

export default class AccountUserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: false,
    };
  }

  componentDidMount() {
    this._getUserDetails();
  }

  async _getUserDetails() {
    this.setState({
      loading: true,
    });
    const { userId: id } = this.props;
    const getUrl = `admin/users/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState({
        user: result.data,
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { user } = this.state;
    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }

    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');

    return (
      <div className="main">
        <div className="dark-blur" />
        <div className="customers-info">
          {this.state.loading ? (
            <LoadingOverlay />
          ) : (
            <>
              <div className="box-header marginless">
                <h2>
                  <i className="fa fa-user" />
                  {` ${user.firstName || ''} ${user.lastName || ''}`}
                </h2>
              </div>

              <div className="customers-main">
                <div className="row">
                  <div className="col">
                    <div className="padded">
                      <div className="row text-white">
                        <div style={{ marginBottom: 20 }} className="col-lg-6">
                          <h4>Basic Info</h4>
                          <hr />
                          <dl>
                            <dt>First name:</dt>
                            <dd>{user.firstName}</dd>
                            <dt>Last name:</dt>
                            <dd>{user.lastName}</dd>
                            <dt>Username:</dt>
                            <dd>{user.username ? user.username : '-'}</dd>
                            <dt>E-mail address:</dt>
                            <dd>{user.email}</dd>
                            <dt>Customer id:</dt>
                            <dd>{user.id}</dd>
                            <dt>Birth date:</dt>
                            <dd>{date(user.birthDate, 'D / MMMM / Y')}</dd>
                            <dt>Onboarding:</dt>
                            <dd>
                              {date(user.registeredAt, 'D / MMMM / Y HH:mm')}
                            </dd>
                          </dl>
                        </div>
                        <div style={{ marginBottom: 20 }} className="col-lg-6">
                          <h4>KYC</h4>
                          <hr />
                          <dl>
                            <dt>Status:</dt>
                            <dd>{newKycStatus.toUpperCase()}</dd>
                          </dl>
                        </div>
                      </div>
                      <div className="row text-white">
                        <div style={{ marginBottom: 20 }} className="col-lg-6">
                          <h4>Address Info</h4>
                          <hr />
                          <dl>
                            <dt>Address Line One:</dt>
                            <dd>
                              {user.addressOne
                                ? user.addressOne.replace('undefined', '')
                                : '-'}
                            </dd>
                            <dt>Address Line Two:</dt>
                            <dd>{user.addressTwo}</dd>
                            <dt>City:</dt>
                            <dd>{user.city}</dd>
                            <dt>Postcode:</dt>
                            <dd>{user.postCode}</dd>
                            <dt>Country code:</dt>
                            <dd>{user.countryCode}</dd>
                            <dt>Mobile:</dt>
                            <dd>{user.mobile}</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
