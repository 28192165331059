import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
// import CreditAplicationsNavigation from './CreditAplicationsNavigation';
// import CreditAplications from './CreditApplications';

export const NAME = 'creditAplications';

export default class CreditAplicationsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'creditAplications.*';
  }

  getNavigation() {
    return [
      {
        label: 'Credit Aplications',
        icon: 'handshake-o',
        path: '/credit-applications',
        permission: 'creditAplications.*',
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}credit-applications`}
        load={() => import('./CreditAplicationsNavigation')}
      />,
    ];
  }
}
