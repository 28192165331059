import BaseModel from '@launchpad/logic/model/BaseModel';

export default class SendNotificationModel extends BaseModel {
  /**
   * Get submit url
   */
  // getSubmitUrl() {
  //   return 'admin/send-notification-request';
  // }

  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list usrl
   */
  getListUrl() {
    return 'admin/send-notification-request';
  }

  getDetailsUrl() {
    return 'admin/send-notification-request';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: true,
        type: 'field',
      },
      {
        name: 'title',
        label: 'Title',
        sortable: false,
        type: 'field',
      },
      {
        name: 'text',
        label: 'Text',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userCount',
        label: 'User count',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: 'created',
        label: 'Sent',
        sortable: false,
        type: 'field',
      },
      // {
      //   name: null,
      //   label: 'Actions',
      //   sortable: false,
      //   type: 'actions',
      // },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^Please enter name',
        },
      },
      title: {
        presence: {
          allowEmpty: false,
          message: '^Please enter notification title',
        },
      },
      text: {
        presence: {
          allowEmpty: false,
          message: '^Please enter notification text',
        },
      },
      // description: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter notification description',
      //   },
      // },
      icon: {
        presence: {
          allowEmpty: false,
          message: '^Please enter notification icon',
        },
      },
      sendToType: {
        presence: {
          allowEmpty: false,
          message: '^Please enter notification target',
        },
      },
      // sendToValue: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter notification target user or user group',
      //   },
      // },
    };
  }

  customValidation(record) {
    const errors = [];

    const { sendToType, sendToValue } = record;

    if (sendToType !== 'ALL' && !sendToValue) {
      errors.push({
        field: 'sendToValue',
        message: 'Please enter notification target user or user group',
      });
    }

    return errors;
  }
}
