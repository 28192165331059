import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';

export const NAME = 'cards';

export default class CardsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'card.*';
  }

  getNavigation() {
    return [
      {
        label: 'Cards',
        icon: 'credit-card',
        path: '/cards',
        permission: 'cards.*',
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}cards`}
        load={() => import('./CardsNavigation')}
      />,
    ];
  }
}
