import React, { Component } from 'react';
import { AppBadge } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import { Link } from 'react-router-dom';
import { currency } from '@launchpad/util/NumberHelper';
import AccountsModel from './models/AccountsModel';
import BreadcrumbHelper from '../../../../@launchpad/util/BreadcrumbHelper';

interface HTMLInputElement extends HTMLElement {
  getContext(arg0: string): any;
  /**
   * Returns the value of the data at the cursor's current position.
   */
  value: string;
  history: any;
  justTable?: boolean;
}

export default class Accounts extends Component<HTMLInputElement, any> {
  _tableComponent: any;

  constructor(props: Readonly<HTMLInputElement>) {
    super(props);
    this.state = {};

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Accounts',
        link: '/accounts',
      },
    ]);
  }

  _renderStatus(item) {
    switch (item.status) {
      case 'active':
      case 'ok':
        return <AppBadge text="OK" icon="check" />;
      case 'locked':
        return <AppBadge text="Locked" icon="lock" type="danger" />;
      case 'closed':
        return <AppBadge text="Stolen" icon="ban" type="danger" />;
      case 'pending_activation':
        return (
          <AppBadge text="Pending activation" icon="clock-o" type="info" />
        );
      default:
        return null;
    }
  }

  render() {
    const inner = (
      <>
        <AppTable
          icon="address-card"
          title="Accounts"
          onInit={(tableComponent) => (this._tableComponent = tableComponent)}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'status':
                return this._renderStatus(item);
              case 'userUuid':
                return (
                  <Link
                    className="userCardLink"
                    to={`/user-details/${item.userUuid}`}
                  >
                    {item.user
                      ? `${item.user.firstName} ${item.user.lastName}`
                      : 'User Details'}
                  </Link>
                );
              case 'availableBalance':
                return currency(
                  item.availableBalance ? item.availableBalance : item.balance,
                  item.currency
                );
              case 'user.additionalData.type':
                return item.user.additionalData.type.toUpperCase();
              default:
            }
          }}
          model={AccountsModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/accounts/details/${member.id}`}
                  className="button-info"
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
      </>
    );

    if (this.props.justTable) return inner;

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-address-card" aria-hidden="true" /> Accounts
        </h1>
        {inner}
      </div>
    );
  }
}
