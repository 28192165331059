import BaseModel from '@launchpad/logic/model/BaseModel';

export default class TransactionModel extends BaseModel {
  getListUrl() {
    return 'admin/transactions';
  }

  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  getDetailsUrl() {
    return 'transaction/details';
  }

  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'note',
        label: 'Note',
        sortable: false,
        type: 'field'
      },
      {
        name: 'amount',
        label: 'Amount',
        sortable: false,
        type: 'field'
      },
      {
        name: 'postDate',
        label: 'Transaction Date',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
