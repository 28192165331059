import { AppRoute } from '@launchpad';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Accounts from './Accounts';
import AccountDetails from './details/AccountDetails';

export default class AccountsNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/accounts/details/:id" component={AccountDetails} />
        <AppRoute path="/accounts" component={Accounts} />
      </Switch>
    );
  }
}
