import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import MoneyMovementRefund from './screens/MoneyMovementRefund';
import MoneyMovementOutboundPayment from './screens/MoneyMovementOutboundPayment';
import PendingAuthorizations from './screens/PendingAuthorizations';
import PendingAuthorizationDetails from './screens/details/PendingAuthorizationsDetails';
import OutboundPaymentTransactions from './screens/details/OutboundPaymentTransactions';

export const NAME = 'moneymovement';

export default class MoneyMovementModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'moneymovement.*';
  }

  getNavigation() {
    return [
      {
        label: 'Money Movement',
        icon: 'share-alt',
        path: null,
        permission: 'moneymovement.*',
        policy: 'Admin:MoneyMovement:*',
        children: [
          {
            label: 'Transfer',
            icon: 'reply',
            path: '/transfer',
            permission: 'moneymovement.refund.*',
          },
          {
            label: 'Outbound payment',
            icon: 'share',
            path: '/outbound-payment',
            permission: 'moneymovement.outboundpayment*',
          },
          {
            label: 'Pending Authorizations',
            icon: 'address-card',
            path: '/pending-authorizations',
            permission: 'moneymovement.pendingauthorizations*',
          },
        ],
      },
    ];
  }

  getRoutes() {
    return [
      <AppRoute path="/transfer" component={MoneyMovementRefund} />,
      <AppRoute
        path="/outbound-payment/transactions/:id"
        component={OutboundPaymentTransactions}
      />,
      <AppRoute
        path="/outbound-payment"
        component={MoneyMovementOutboundPayment}
      />,
      <AppRoute
        path="/pending-authorizations/:id"
        component={PendingAuthorizationDetails}
      />,
      <AppRoute
        path="/pending-authorizations"
        component={PendingAuthorizations}
      />,
    ];
  }
}
