import React from 'react';
import { AppRoute } from '@launchpad';
import UserTiersNavigation from '@launchpad/modules/users/user-tiers/UserTiersNavigation';
import BaseModule from '@launchpad/logic/module/BaseModule';
import UsersNavigation from './UsersNavigation';
import Users from './Users';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'users.*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: null,
        permission: 'users.*',
        children: [
          {
            label: 'Users',
            icon: 'users',
            path: '/',
            permission: 'users.*',
          },
          {
            label: 'User tiers',
            icon: 'users',
            path: '/user-tiers',
            permission: 'userTiers.*',
            policy: 'Admin:Users:UserTiers:*',
          },
        ],
      },
    ];
  }

  getRoutes() {
    return [
      <AppRoute path="/" component={Users} exact />,
      <AppRoute path="/user-details" component={UsersNavigation} />,
      <AppRoute path="/user-tiers" component={UserTiersNavigation} />,
    ];
  }
}
