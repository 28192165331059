import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import AccountTransactions from '@launchpad/modules/accounts/account/tabs/AccountTransactions';
import FeeCollection from '@launchpad/modules/fee/FeeCollection';
import { LoadingOverlay } from '@launchpad';
import AccountBasicInfo from './tabs/AccountBasicInfo';
import AccountUserDetails from './tabs/AccountUserDetails';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      activeTab: 'basic',
      loading: false,
    };
  }

  componentDidMount() {
    this._getAccountDetails();
  }

  getTabs(account) {
    const basicInfo = (
      <AccountBasicInfo fetcher={this._fetcher} account={account} />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo,
      },
      {
        id: 'user',
        icon: 'user',
        title: 'User details',
        component: <AccountUserDetails userId={account.userUuid} />,
      },
      {
        id: 'fee-collection',
        icon: 'money',
        title: 'Fee Collection',
        component: <FeeCollection account={account} {...this.props} />,
      },
      {
        id: 'transactions',
        icon: 'exchange',
        title: 'Transactions',
        component: <AccountTransactions account={account} {...this.props} />,
      },
    ];
    return allTabs;
  }

  async _getAccountDetails() {
    this.setState({
      loading: true,
    });
    const { id } = this.props.match.params;
    const getUrl = `admin/accounts/${id}`;
    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState(
        {
          account: result.data,
          loading: false,
        },
        () => {
          BreadcrumbHelper.setCrumbs([
            {
              label: 'Accounts',
              link: '/accounts',
            },
          ]);
          if (this.state.account?.name) {
            BreadcrumbHelper.addCrumb(this.state.account.name);
          }
        }
      );
    } catch (e) {}
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  _renderContent(accountDetails) {
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> Account Info:{' '}
          {accountDetails.name}
        </h1>
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <AppTabs
            onTabChange={(activeTab) => this.setState({ activeTab })}
            activeTab={this.state.activeTab}
            tabs={this.getTabs(accountDetails)}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.account)}
      </div>
    );
  }
}

// Inject router
UserDetails.contextTypes = {
  router: PropTypes.object,
};
