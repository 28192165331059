import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class CardModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/payment-devices';
  }

  /**
   * Get lock card url
   */
  getLockCardUrl(id) {
    return `admin/payment-devices/${id}/lock`;
  }

  async lockCard(id) {
    const result = await Api.call(this.getLockCardUrl(id), 'POST');
    return result;
  }

  /**
   * Get unlock card url
   */
  getUnlockCardUrl(id) {
    return `admin/payment-devices/${id}/unlock`;
  }

  async unlockCard(id) {
    const result = await Api.call(this.getUnlockCardUrl(id), 'POST');
    return result;
  }

  /**
   * Get details url
   */
  // getDetailsUrl() {
  //   return 'cards/details';
  // }

  /**
   * Get submit url
   */
  // getSubmitUrl() {
  //   return 'cards/order';
  // }

  /**
   * Get download url
   */
  // getDownloadUrl() {
  //   return 'cards/list/download';
  // }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'type',
        label: 'Type',
        sortable: false,
        type: 'field',
      },
      {
        name: 'token',
        label: 'Token',
        sortable: false,
        type: 'field',
      },
      {
        name: 'user.additionalData.type',
        label: 'User type',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userId',
        label: 'User',
        sortable: false,
        type: 'field',
      },
      {
        name: 'balance',
        label: 'Balance',
        sortable: false,
        type: 'field',
      },
      {
        name: 'expiryDate',
        label: 'Expires',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get transaction of the card
   *
   * @param {Integer} id Id of the card
   */
  async getTransaction(id) {
    const result = await Api.call('transaction/list', {
      id,
    });

    return result.data;
  }

  /**
   * Change freeze status
   *
   * @param {Object} card Card to change freeze status
   * @param {Bool} status Frozen or not
   */
  async changeFreezeStatus(card, status) {
    const response = await this.freezeCard(card.id, status);

    if (response.success) {
      return true;
    }

    return false;
  }

  async freezeCard(id, status) {
    const response = await Api.call('card/lock-unlock', {
      id,
      locked: !!status,
    });

    return response;
  }

  /**
   * Change stolen status
   *
   * @param {Object} card Card to change stolen status
   * @param {Bool} status Stolen or not
   */
  async changeStolenStatus(card, status) {
    const response = await this.stolenCard(card.id, status);

    if (response.success) {
      return true;
    }

    return false;
  }

  async regenerateCard(card, status) {
    const response = await Api.call('card/regenerate', {
      id: card.id,
      status,
    });

    if (response.success) {
      return response.data;
    }

    return response.data;
  }

  /**
   * Marks card as stolen
   *
   * @param {integer} id Id of the card
   */
  async stolenCard(id) {
    const response = await Api.call('card/report-stolen', {
      id,
    });

    return response;
  }

  async update3DS(id) {
    const response = await Api.call('payment-device/update-3ds', { id });
    return response.success;
  }

  async remove3DS(id) {
    const response = await Api.call('payment-device/remove-3ds', { id });
    return response.success;
  }

  // async changeCardStatusPHP(id, status) {
  //   const response = await Api.call('card/change-status', {
  //     id,
  //     status,
  //   });

  //   return response.success;
  // }

  async changeCardStatus(id, status) {
    const result = await Api.call(
      `admin/payment-devices/${id}/change-status`,
      'POST',
      {
        status,
      }
    );
    return result;
  }
}
