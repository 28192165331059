/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import ToastHelper from '@launchpad/util/ToastHelper';
import AppSelect from '@launchpad/components/crud/AppSelect';
import BaseModel from '@launchpad/logic/model/BaseModel';
import {
  FormHandler,
  FormSubmitWrapper,
  InputField,
  ModalDialog,
} from '../../../index';
import { getCurrencySymbol } from '../../../util/CurrencyHelper';
import RefundModel from '../models/RefundModel';

export default class RefundModal extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new RefundModel({
        fromAccountId: this.props.account?.id,
        toAccountId: '',
        amount: '',
        currency: this.props.account.currency ?? 'GBP',
      })
    );
  }

  onSubmitSuccess() {
    this.props.onClose();
  }

  onSubmitError(error) {
    // if error.code === 422 consider request as successful
    if (error.code === 422) {
      ToastHelper.show(
        'Your request has been successfully created.',
        'success'
      );
    } else {
      ToastHelper.show('Something went wrong, please try again later.');
    }
    this.props.onClose();
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return 'Refund successfully finished!';
    }
    return null;
  }

  render() {
    const { formHandler } = this;
    return (
      <ModalDialog
        show={this.props.showModal}
        onClose={() => this.props.onClose()}
      >
        <div>
          <h3>Transfer</h3>
          <div
            className="element-with-blur"
            style={{ paddingTop: '2px', marginRight: 20 }}
          >
            <div className="add-new-group" style={{ position: 'relative' }}>
              <div className="container">
                <FormSubmitWrapper
                  formHandler={formHandler}
                  to={this.props.redirectRoute}
                  buttonText="Initiate transfer"
                  customCloseFunction={this.props.onClose}
                  getModalMessage={(data) => this._getModalMessage(data)}
                >
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="From account"
                    type="text"
                    name="title"
                    value={this.props.account?.iban || this.state.record.fromAccountId}
                    handler={formHandler}
                  />
                  <AppSelect
                    getOptionValue={(item) => item.id}
                    getOptionLabel={(item) => item.iban || item.accountNumber || item.id}
                    wrapperStyle={{
                      marginBottom: 10,
                      marginTop: 10,
                      zIndex: 9999999,
                    }}
                    textFieldProps={{
                      label: 'To account*',
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    params={{
                      limit: 100,
                      page: 1,
                    }}
                    model={InternalAccountsModal}
                    name="toAccountId"
                    value={this.state.record.toAccountId}
                    handler={formHandler}
                    placeholder="Select account"
                  />
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    type="number"
                    label="Transfer Amount*"
                    name="amount"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.amount}
                    handler={formHandler}
                    prefix={getCurrencySymbol(this.state.record.currency)}
                  />
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

class InternalAccountsModal extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/accounts?type=INTERNAL';
  }
}

RefundModal.defaultProps = {
  entityId: null,
  entity: null,
};
