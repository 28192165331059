import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import Api from '../../logic/api/Api';
import { SHOULD_SHOW_IS_LANDING } from '../../logic/store/AppActions';
import {
  MEMBER_AUTH_LOADED, MEMBER_AUTH_REFRESH, MEMBER_LOGIN_TRIGGER,
  MEMBER_LOGOUT_TRIGGER, MEMBER_TOKEN_LOADED
} from './AuthActions';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* loginMember(action) {
  try {
    const accessToken = action.payload.token;
    // Set token in Api class (in-memory)
    Api.setToken(accessToken);
    const tokenToStore =
      OverrideService.getConfig().authStrategy === 'access-token'
        ? accessToken
        : '*********';
    window.localStorage.setItem('AUTH_TOKEN', tokenToStore);

    if (action.payload.token) {
      const result = yield Api.call('auth/user/me', 'GET');
      if (OverrideService.getConfig().ignorePermissions) {
        result.data.userPermissions = { allow: ['*'] };
      }
      if (OverrideService.getOverrides().filterAdminByRoles) {
        result.data.userPermissions = OverrideService.getOverrides().filterAdminByRoles(
          result.data.userRoles
        );
      }
      if(result.data.userData.status === 'pending_password_change') {
        window.location.replace('/login/change-password');
        return;
      }
      if (result.success) {
        yield put({ type: MEMBER_AUTH_LOADED, payload: result.data });
      }
      
    } else {
      yield put({ type: MEMBER_AUTH_LOADED, payload: action.payload.account });
    }
  } catch (e) {}
}

function logoutMember(action) {
  try {
    window.localStorage.removeItem('AUTH_TOKEN', action.payload.token);
  } catch (e) {}
}

function* refreshAuth(action) {
  try {
    const token = window.localStorage.getItem('AUTH_TOKEN');

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });

      const result = yield Api.call('auth/user/me', 'GET');
      if (OverrideService.getConfig().ignorePermissions) {
        result.data.userPermissions = { allow: ['*'] };
      }
      if (OverrideService.getOverrides().filterAdminByRoles) {
        result.data.userPermissions = OverrideService.getOverrides().filterAdminByRoles(
          result.data.userRoles
        );
      }
      if(result.data.userData.status === 'pending_password_change') {
        yield put({ type: SHOULD_SHOW_IS_LANDING, payload: false });
        window.location.push('/login/change-password');
        return;
      }

      if (result.success) {
        yield put({ type: MEMBER_AUTH_LOADED, payload: result.data });
      }

    }
    yield put({ type: SHOULD_SHOW_IS_LANDING, payload: false });
  } catch (e) {}
}

export function* watchMemberLogin() {
  yield takeEvery(MEMBER_LOGIN_TRIGGER, loginMember);
}
export function* watchMemberLogout() {
  yield takeEvery(MEMBER_LOGOUT_TRIGGER, logoutMember);
}
export function* watchRefreshAuth() {
  yield takeEvery(MEMBER_AUTH_REFRESH, refreshAuth);
}

export default function* authSagas() {
  yield all([
    fork(watchMemberLogin),
    fork(watchMemberLogout),
    fork(watchRefreshAuth)
  ]);
}
