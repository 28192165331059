import React from 'react';
import { currency } from '@launchpad/util/NumberHelper';
import { LoadingOverlay } from '@launchpad';
import { date } from '@launchpad/util/DateHelper';

const AuthorizationInfo = (props) => {
  const { authorization } = props;

  return (
    <div className="main" key={authorization.id}>
      <div className="dark-blur" />
      <div className="customers-info">
        <div className="customers-main">
          <div className="row">
            <div className="col">
              {!authorization.id ? (
                <LoadingOverlay />
              ) : (
                <div className="padded">
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>Basic Authorization Info</h4>
                      <hr />
                      <dl>
                        <dt>Id:</dt>
                        <dd>{authorization.id}</dd>
                        <dt>Status:</dt>
                        <dd>{authorization.status}</dd>
                        <dt>Created at:</dt>
                        <dd>
                          {date(
                            authorization.createdAt,
                            'DD / MMMM / Y HH:mm:ss'
                          ) || '-'}
                        </dd>
                        <dt>Expires at:</dt>
                        <dd>
                          {date(
                            authorization.expires,
                            'DD / MMMM / Y HH:mm:ss'
                          ) || '-'}
                        </dd>
                        <dt>Amount:</dt>
                        <dd>
                          {currency(
                            authorization.additionalData.amount,
                            authorization.additionalData.currency || 'GBP'
                          )}
                        </dd>

                        <dt>Beneficiary:</dt>
                        <dd>{authorization.additionalData.beneficiary.name}</dd>
                        <dt>To account number:</dt>
                        <dd>
                          {
                            authorization.additionalData.beneficiary
                              .accountNumber
                          }
                        </dd>
                        <dt>Sort code:</dt>
                        <dd>
                          {authorization.additionalData.beneficiary.sortCode}
                        </dd>
                        <dt>Reference:</dt>
                        <dd>{authorization.additionalData.reference}</dd>
                      </dl>
                    </div>
                    <br />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizationInfo;
