import React from 'react';
import { Link } from 'react-router-dom';
import AppTable from '@launchpad/components/crud/AppTable';
import { currency } from '@launchpad/util/NumberHelper';
import { date } from '@launchpad/util/DateHelper';
import { AppBadge } from '@launchpad';
import CardModel from '../../../cards/models/CardModel';

export default class UserCards extends React.Component {
  _tableComponent;

  _renderStatus(item) {
    switch (item.status) {
      case 'active':
      case 'ok':
        return <AppBadge text="OK" icon="check" />;
      case 'locked':
        return <AppBadge text="Locked" icon="lock" type="danger" />;
      case 'stolen':
        return <AppBadge text="Stolen" icon="ban" type="danger" />;
      case 'inactive':
        return <AppBadge text="Inactive" icon="ban" type="danger" />;
      case 'expired':
        return <AppBadge text="Expired" icon="ban" type="danger" />;
      case 'destroyed':
        return <AppBadge text="Destroyed" icon="ban" type="danger" />;
      case 'restricted':
        return <AppBadge text="Restricted" icon="ban" type="danger" />;
      case 'voided':
        return <AppBadge text="Voided" icon="ban" type="danger" />;
      case 'lost':
        return <AppBadge text="Lost" icon="ban" type="danger" />;
      case 'security_violation':
        return <AppBadge text="Security violation" icon="ban" type="danger" />;
      case 'refunded':
        return <AppBadge text="Refunded" icon="ban" type="danger" />;
      case 'pending_activation':
        return (
          <AppBadge text="Pending activation" icon="clock-o" type="info" />
        );
      case 'cardholder_to_contact_issuer':
        return (
          <AppBadge
            text="Cardholder to contact issuer"
            icon="clock-o"
            type="info"
          />
        );
      default:
    }
  }

  render() {
    const inner = (
      <>
        <AppTable
          icon="credit-card-alt"
          title="Cards"
          onInit={(tableComponent) => (this._tableComponent = tableComponent)}
          params={{
            userId: this.props.user ? this.props.user.id : undefined,
          }}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          // headerActions={
          //   <Link
          //     // to={`/users/${this.props.user.id}/add-new-account`}
          //     to={`${this.props.user.id}/add-new-account`}
          //     className="button width-240"
          //     style={{ marginRight: '10px' }}
          //   >
          //     <i className="fa fa-fw fa-plus" />
          //     Add new
          //   </Link>
          // }
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'status':
                return this._renderStatus(item);
              default:
            }

            if (column.name === 'balance') {
              return currency(
                item.account.availableBalance,
                item.account.currency
              );
            }
            if (column.name === 'expiryDate') {
              return date(item.expiryDate, 'MM / YY');
            }
          }}
          getTableColumns={() => [
            {
              name: 'id',
              label: 'Id',
              sortable: false,
              type: 'field',
            },
            {
              name: 'type',
              label: 'Type',
              sortable: false,
              type: 'field',
            },
            {
              name: 'token',
              label: 'Token',
              sortable: false,
              type: 'field',
            },
            {
              name: 'balance',
              label: 'Balance',
              sortable: false,
              type: 'field',
            },
            {
              name: 'expiryDate',
              label: 'Expires',
              sortable: false,
              type: 'field',
            },
            {
              name: 'status',
              label: 'Status',
              sortable: false,
              type: 'field',
            },
            {
              name: null,
              label: 'Actions',
              sortable: false,
              type: 'actions',
            },
          ]}
          model={CardModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/cards/details/${member.id}`}
                  className="button-info"
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
      </>
    );

    if (this.props.justTable) return inner;

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-credit-card-alt" aria-hidden="true" /> Cards
        </h1>
        {inner}
      </div>
    );
  }
}
