import { Method } from 'axios';
import Config from '../../config';
import Api from '../api/Api';

export default class BaseModel {
  data: any;

  constructor(data:any = null) {
    this.data = data;
  }

  getMethod(): string {
    return 'POST';
  }

  getSubmitUrl(): string | null {
    return null;
  }

  getSubmitMethod() {
    return 'POST';
  }

  getListUrl(params: any = null): string | null {
    return null;
  }

  getListRequestData(params): any {
    return params;
  }

  getDetailsUrl(id: string | null = null): string | null {
    return null;
  }

  getDetailsRequestData(id): Record<string, unknown> {
    return {
      id
    };
  }

  getEmptyRecord(): unknown {
    return {};
  }

  getData() {
    if (this.data) {
      return this.data;
    }
    return this.getEmptyRecord();
  }

  async fetch(passedParams) {
    // Enable option to not pass params
    const params =
      passedParams === undefined
        ? { page: '1', limit: '', value: '' }
        : passedParams;

    const url = new URL(Config.url + this.getListUrl(params));
    url.search = params ? String(new URLSearchParams(params)) : '';

    const getUrl = this.getListUrl(params) + url.search;

    const result = await Api.call(
      getUrl,
      this.getMethod() as Method,
      this.getListRequestData(params)
    );

    return result;
  }

  async fetchFlat(params = {}) {
    const result = await Api.call(this.getListUrl(params) as string, this.getMethod() as Method, {
      ...this.getListRequestData(params),
      flat: true
    }) as { data: any, success: boolean };

    return result.data;
  }

  async get(id, isIdInUrl = false) {
    let result: any = null;

    if(isIdInUrl){
      result = await Api.call(
        this.getDetailsUrl(id) as string,
        this.getMethod() as Method,
      );
    }else{
      result = await Api.call(
        this.getDetailsUrl() as string,
        this.getMethod() as Method,
        this.getDetailsRequestData(id)
      );
    }

    return result.data;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {};
  }

  /**
   * Custom (model specific) validation
   */
  customValidation() {
    return [];
  }

  getTableColumns() {
    return [];
  }
}
