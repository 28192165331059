import React, { Component } from 'react';
import Transactions from './TransactionsList';

export default class UserTransactions extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Transactions user={this.props.user} />
        </div>
      </div>
    );
  }
}
