import React from 'react';
import { Link } from 'react-router-dom';
import AppTable from '@launchpad/components/crud/AppTable';
import { currency } from '@launchpad/util/NumberHelper';
import AccountsModel from '../../../accounts/models/AccountsModel';

export default class UserAccounts extends React.Component {
  _tableComponent;

  render() {
    const inner = (
      <>
        <AppTable
          icon="address-card"
          title="Accounts"
          onInit={(tableComponent) => (this._tableComponent = tableComponent)}
          params={{
            userId: this.props.user ? this.props.user.id : undefined,
          }}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          // headerActions={
          //   <Link
          //     // to={`/users/${this.props.user.id}/add-new-account`}
          //     to={`${this.props.user.id}/add-new-account`}
          //     className="button width-240"
          //     style={{ marginRight: '10px' }}
          //   >
          //     <i className="fa fa-fw fa-plus" />
          //     Add new
          //   </Link>
          // }
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'availableBalance':
                return currency(item.availableBalance, item.currency);
              default:
            }
          }}
          getTableColumns={() => [
            {
              name: 'id',
              label: 'Id',
              sortable: false,
              type: 'field',
            },
            {
              name: 'accountNumber',
              label: 'Account number',
              sortable: false,
              type: 'field',
            },
            {
              name: 'name',
              label: 'Name',
              sortable: false,
              type: 'field',
            },
            {
              name: 'iban',
              label: 'IBAN',
              sortable: false,
              type: 'field',
            },
            {
              name: 'availableBalance',
              label: 'Available balance',
              sortable: false,
              type: 'field',
            },
            {
              name: null,
              label: 'Actions',
              sortable: false,
              type: 'actions',
            },
          ]}
          model={AccountsModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/accounts/details/${member.id}`}
                  className="button-info"
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
      </>
    );

    if (this.props.justTable) return inner;

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-address-card" aria-hidden="true" /> Accounts
        </h1>
        {inner}
      </div>
    );
  }
}
