import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay } from '@launchpad';
import AuthorizationInfo from './tabs/AuthorizationInfo';
import AppTabs from '../../../../components/crud/tabs/AppTabs';

export default class PendingAuthorizationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'basic',
      loading: false,
      authorization: props.location.state.item,
    };
  }

  getTabs() {
    const basicInfo = (
      <AuthorizationInfo authorization={this.state.authorization} />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo,
      },
    ];
    return allTabs;
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  _renderContent() {
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> Authorization Info:
        </h1>
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <AppTabs
            onTabChange={(activeTab) => this.setState({ activeTab })}
            activeTab={this.state.activeTab}
            tabs={this.getTabs()}
          />
        )}
      </div>
    );
  }

  render() {
    return <div className="dashboard-management">{this._renderContent()}</div>;
  }
}

// Inject router
PendingAuthorizationDetails.contextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object,
};
