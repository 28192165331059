import React from 'react';
import configuration from './configuration/index';
import LaunchPadAdmin from '../@launchpad/LaunchPadAdmin';
import './configuration/styles/index';
import './configuration/styles/custom.scss';

const Main = () => {
  return (
    <div>
      <LaunchPadAdmin configuration={configuration} />
    </div>
  );
};

export default Main;
