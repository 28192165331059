export const getNavigation = (appNavigation: any) => {
  const navigation = appNavigation.filter(
    (x) =>
      // x.slug !== 'kyc' &&
      // x.label !== 'FX Rates' &&
      // x.label !== 'Analytics' &&
      // x.label !== 'Notifications' &&
      // x.label !== 'Rewards' &&
      // x.label !== 'Shop' &&
      // x.label !== 'Chat' &&
      x.label !== 'Dictionary'
  );

  // const contentManagement = navigation.find(
  //   x => x.slug === 'content-management'
  // );

  // const children = contentManagement.children.filter(x => x.slug !== 'faq');

  // contentManagement.children = children;

  // const tools = navigation.find(x => x.slug === 'tools');

  // tools.children = tools.children.filter(x => x.label !== 'Bulk Load');
  // tools.children.push({
  //   label: 'Load Report',
  //   icon: 'file',
  //   path: '/tools/load-report',
  //   permission: 'tools.loadReport.*'
  // });

  return navigation;
};

export default {
  getNavigation,
};
