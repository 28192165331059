import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';

export const NAME = 'transactions';

export default class TransactionsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'transaction.*';
  }

  getNavigation() {
    return [
      {
        label: 'Transactions',
        icon: 'exchange',
        path: '/transaction',
        permission: 'transactions.*',
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}transaction`}
        load={() => import('./TransactionsNavigation')}
      />,
    ];
  }
}
