import React, { Component } from 'react';
import { LoadingOverlay } from '@launchpad/components';
import AppTable from '@launchpad/components/crud/AppTable';
import OutboundPaymentTransactionModal from '@launchpad/modules/money-movement/models/OutboundPaymentTransactionsModel';
import { currency } from '@launchpad/util/NumberHelper';
import { date } from '@launchpad/util/DateHelper';

export default class OutboundAccountTransactions extends Component {
  render() {
    const { account } = this.props;

    return (
      <div className="dashboard-management">
        {!account || !account.id ? (
          <LoadingOverlay />
        ) : (
          <AppTable
            icon="exchange"
            title="Transactions"
            params={{
              accountId: account.id,
            }}
            filter={[
              {
                name: 'term',
                type: 'term',
              },
            ]}
            getRowClass={(item) => {
              if (!item) return null;
              switch (true) {
                case item.status === 'VOIDED':
                case item.status === 'FAILED':
                case item.status === 'DECLINED':
                  return 'spend';
                default:
                  return 'load';
              }
            }}
            renderCell={(column, item) => {
              if (column.name === 'amount') {
                return currency(item.amount, item.amountCurrency || 'GBP');
              }
              if (column.name === 'postDate') {
                return date(item.postDate, 'D / MMMM / Y');
              }
            }}
            model={OutboundPaymentTransactionModal}
          />
        )}
      </div>
    );
  }
}
