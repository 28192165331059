/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import UserTierUserInfoElement from '@launchpad/modules/users/user-tiers/components/UserTierUserInfoElement';
import RenderIfAllowed from '@launchpad/util/access-control/access-control.component';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import { LPOverridable } from '../../../../../../@launchpad/components';
import UsersModel from '../../models/UsersModel';
import { LoadingOverlay } from '../../../../../../@launchpad/index';

export default class CustomerBasicInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      redirect: false,
      isLoading: false,
    };
  }

  onChangeUserStatusKyc(id, status) {
    this.setState({ isLoading: true });
    new UsersModel()
      .changeUserStatusKYC(id, status)
      .then(() => {
        this.props.onRefresh();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }
    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');

    const userType = user.additionalData
      ? user.additionalData.type.toUpperCase()
      : '-';

    const userKycStatus = user.kycStatus ? user.kycStatus.toUpperCase() : '';

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
            {/* <div>
              <div className="bottom" style={{ alignItems: 'center' }}>
                <Link
                  to={`/users/all-users/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </div>
            </div> */}
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <LPOverridable
                      name="user.details.basic.before"
                      user={user}
                    />
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Basic Info</h4>
                        <hr />
                        <dl>
                          <dt>First name:</dt>
                          <dd>{user.firstName || '-'}</dd>
                          <dt>Last name:</dt>
                          <dd>{user.lastName || '-'}</dd>
                          <dt>Username:</dt>
                          <dd>{user.username ? user.username : '-'}</dd>
                          <dt>E-mail address:</dt>
                          <dd>{user.email || '-'}</dd>
                          <dt>User type:</dt>
                          <dd>{userType || '-'}</dd>
                          <dt>Customer id:</dt>
                          <dd>{user.id || '-'}</dd>
                          <dt>Birth date:</dt>
                          <dd>
                            {user.birthDate
                              ? date(
                                  user.birthDate.slice(0, 10),
                                  'D / MMMM / Y'
                                )
                              : '-'}
                          </dd>
                          <dt>Onboarding:</dt>
                          <dd>
                            {user.registeredAt
                              ? date(
                                  user.registeredAt.slice(0, 10),
                                  'D / MMMM / Y'
                                )
                              : '-'}
                          </dd>
                          <dt>Card token:</dt>
                          <dd>{user.token || '-'}</dd>
                        </dl>
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <select
                            className="option-wrapper"
                            onChange={(event) =>
                              this.onChangeUserStatusKyc(
                                user.id,
                                event.target.value
                              )
                            }
                            value={
                              userKycStatus !== 'UNVERIFIED'
                                ? userKycStatus
                                : ''
                            }
                          >
                            <option disabled value="">
                              -- select an option --
                            </option>
                            <option value="PENDING_FRONTEND_VERIFICATION">
                              PENDING DOCUMENTS
                            </option>
                            <option value="VERIFICATION_IN_PROGRESS">
                              VERIFICATION IN PROGRESS
                            </option>
                            <option value="VERIFIED">VERIFIED</option>
                            <option value="REJECTED">REJECTED</option>
                          </select>
                        </div>
                        <RenderIfAllowed action="Admin:Users:UserTiers:*">
                          <div style={{ marginTop: 20 }}>
                            <UserTierUserInfoElement
                              user={user}
                              onSuccess={() => window.location.reload()}
                            />
                          </div>
                        </RenderIfAllowed>
                      </div>
                    </div>
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Address Info</h4>
                        <hr />
                        <dl>
                          <dt>Address Line One:</dt>
                          <dd>
                            {user.addressOne
                              ? user.addressOne.replace('undefined', '')
                              : '-'}
                          </dd>
                          <dt>House name:</dt>
                          <dd>{user.additionalData?.houseName || '-'}</dd>
                          <dt>Flat number:</dt>
                          <dd>{user.flatNumber || '-'}</dd>
                          <dt>City:</dt>
                          <dd>{user.city || '-'}</dd>
                          <dt>Postcode:</dt>
                          <dd>{user.postCode || '-'}</dd>
                          <dt>Country code:</dt>
                          <dd>{user.countryCode || '-'}</dd>
                          <dt>Mobile:</dt>
                          <dd>{user.phoneNumber || '-'}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
