// import { IModuleType } from '../../@launchpad/modules/ModulesService';
import ChatModule from '@launchpad/modules/chat/ChatModule';
import MoneyMovementModule from '@launchpad/modules/money-movement/MoneyMovementModule';
import AccountsModule from './accounts/AccountsModule';
import CardsModule from './cards/CardsModule';
import ReportsModule from './reports/ReportsModule';
import TransactionsModule from './transactions/TransactionsModule';
import UsersModule from './users/UsersModule';
import CreditAplicationsModule from './credit-aplications/CreditAplicationsModule';

const modules: any = [
  new UsersModule(),
  new CreditAplicationsModule(),
  new AccountsModule(),
  new CardsModule(),
  new TransactionsModule(),
  new MoneyMovementModule(),
  new ReportsModule(),
  new ChatModule(),
];

export default modules;
