import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay } from '@launchpad';
import AppTabs from '../../../../components/crud/tabs/AppTabs';
import OutboundAccountTransactions from './tabs/OutboundAccountTransactions';

export default class OutboundPaymentTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'basic',
      loading: false,
      account: props.location.state.item,
    };
  }

  getTabs() {
    const allTabs = [
      {
        id: 'transactions',
        icon: 'exchange',
        title: 'Transactions',
        component: (
          <OutboundAccountTransactions
            account={this.state.account}
            {...this.props}
          />
        ),
      },
    ];
    return allTabs;
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  _renderContent() {
    return (
      <div>
        <h1>
          <i className="fa fa-exchange" aria-hidden="true" /> Account
          transactions
        </h1>
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <AppTabs
            onTabChange={(activeTab) => this.setState({ activeTab })}
            activeTab={this.state.activeTab}
            tabs={this.getTabs()}
          />
        )}
      </div>
    );
  }

  render() {
    return <div className="dashboard-management">{this._renderContent()}</div>;
  }
}

// Inject router
OutboundPaymentTransactions.contextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object,
};
