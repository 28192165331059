import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class OutboundPaymentModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/accounts';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/dnd/transactions/outbound-payment';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'accountNumber',
        label: 'Account number',
        sortable: false,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'iban',
        label: 'IBAN',
        sortable: false,
        type: 'field',
      },
      {
        name: 'balance',
        label: 'Balance',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userUuid',
        label: 'User',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  getValidationRules() {
    return {
      'beneficiary.accountNumber': {
        presence: {
          allowEmpty: false,
          message: 'Please enter account number',
        },
      },
      amount: {
        presence: {
          allowEmpty: false,
          message: 'Please enter an amount',
        },
      },
      'beneficiary.sortCode': {
        format: {
          pattern: '[0-9]{6,6}',
          message: 'Sort code must be 6 digits long',
        },
      },
      'beneficiary.name': {
        presence: {
          allowEmpty: false,
          message: 'Please enter name',
        },
      },
    };
  }
}
