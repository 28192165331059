/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { Link } from 'react-router-dom';
import NumberHelper from '@launchpad/util/NumberHelper';
import Api from '@launchpad/logic/api/Api';
import { useSelector } from 'react-redux';
import { date } from '@launchpad/util/DateHelper';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import ToastHelper from '@launchpad/util/ToastHelper';
import React, { useEffect, useState } from 'react';
import PendingAuthorizationsModel from '../models/PendingAuthorizationsModel';
import OTPModal from '../modals/OTPModal';

let tableComponentRef;
const PendingAuthorizations = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useSelector((state: any) => state.auth.profile);

  useEffect(() => {
    BreadcrumbHelper.setCrumbs([
      {
        label: 'Pending Authorizations',
        link: '/',
      },
    ]);
  }, []);

  function onDeleteItem(authId) {
    new PendingAuthorizationsModel()
      .delete(authId)
      .then(() => {
        tableComponentRef._refresh();
      })
      .catch(() => {
        tableComponentRef._refresh();
      });
  }

  return (
    <div className="dashboard-management authorizations-table">
      <h1>
        <i className="fa fa-address-card" aria-hidden="true" /> Pending
        authorizations
      </h1>
      <>
        <AppTable
          onInit={(tableComponent) => (tableComponentRef = tableComponent)}
          icon="address-card"
          title="Pending Authorizations"
          params={{
            action: 'four_eyes_check',
            status: 'pending',
          }}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          renderCell={(column, item) => {
            switch (column.name) {
              case 'createdAt':
                return date(item.createdAt, 'DD/MM/YYYY HH:mm');
              case 'additionalData.beneficiary.accountNumber':
                return item.additionalData?.beneficiary?.accountNumber  ? item.additionalData?.beneficiary?.accountNumber  :
                  item.additionalData?.toAccountId
                  ? `${item.additionalData.toAccountId.substring(0, 10)}...`
                  : '-';
              case 'additionalData.amount':
                return item.additionalData?.amount
                  ? NumberHelper.currency(
                      item.additionalData.amount,
                      'GBP' as any
                    )
                  : null;
              default:
            }
          }}
          model={PendingAuthorizationsModel}
          getColumnActions={(item) => {
            return (
              <div>
                <DeleteButton
                  btnText="Cancel"
                  onConfirm={() => onDeleteItem(item.id)}
                  text="Are you sure you want to cancel authorization?"
                />
                {item.status === 'pending' && item.creatorId !== id && (
                  <>
                    <a
                      href="javascript:void(0)"
                      className="button-info"
                      style={{ backgroundColor: '#12b412' }}
                      onClick={() => {
                        setShowModal(item.id);
                        new PendingAuthorizationsModel().submitAuthorization(
                          item.id
                        );
                      }}
                    >
                      Approve payment
                    </a>
                    {showModal === item.id && (
                      <OTPModal
                        showModal={showModal === item.id}
                        publicHash={item.publicHash}
                        onSubmitSuccess={(data) => {
                          // TODO: Temporary leave it here, this should be refactored
                          Api.call(
                            item?.additionalData?.beneficiary?.name !==
                              undefined
                              ? 'admin/dnd/transactions/outbound-payment'
                              : 'admin/dnd/transactions/inter-ledger',
                            'POST',
                            { ...item.additionalData },
                            undefined,
                            undefined,
                            { 'Authorization-Hash': data.data.hash }
                          )
                            .catch((e) => {
                              if (e.errors.length > 0 && e.errors[0].message) {
                                ToastHelper.show(
                                  e.errors[0].message ||
                                    'Something went wrong, please try again later.'
                                );
                              }
                            })
                            .finally(() => {
                              if (tableComponentRef) {
                                tableComponentRef._refresh();
                              }
                            });
                        }}
                        onClose={() => setShowModal(false)}
                      />
                    )}
                  </>
                )}
                <Link
                  to={{
                    pathname: `/pending-authorizations/${item.id}`,
                    state: { item },
                  }}
                  className="button-info"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
      </>
    </div>
  );
};

export default PendingAuthorizations;
