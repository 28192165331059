/* eslint-disable max-classes-per-file */
import PermissionService from '@launchpad/logic/services/PermissionService';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { push } from 'react-router-redux';

/**
 * Map state to props
 *
 * @param {Object} state
 */
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    profile: state.auth.profile,
    permissions: state.auth.permissions
  };
};

/**
 * Map dispatch to props
 *
 * @param {function} dispatch
 */
const mapDispatchToProps = dispatch => {
  return {
    redirect: token => dispatch(push(token))
  };
};

export class AppRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: null
    };
  }

  render() {
    // Prevent authenticated to access public routes
    if (this.props.unAuthenticatedOnly && this.props.profile) {
      return <Redirect to="/" />;
    }

    // Filter routes based on permissions
    // TODO: Remove permissions, rely on policies
    if(this.props.policy) {
      if(!PermissionService.hasPolicy(
        this.props.policy,
      ))
      return <Redirect to="/" />;
    } 
    
    if (
      this.props.permission &&
      !PermissionService.checkPermissionSet(
        this.props.permission,
        this.props.permissions
      )
    ) {
      return <Redirect to="/" />;
    }

    // Prevent nonauthenticated to access private routes
    if (this.props.authenticatedOnly && !this.props.profile) {
      return <Redirect to="/login" />;
    }

    let { component: Component, layout: Layout, ...rest } = this.props;

    if (this.props.load) {
      const {load} = this.props;
      Component = () => <DynamicLoad load={load} />;
    }

    if (Layout) {
      return (
        <Route
          {...rest}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    }
    return <Route {...rest} render={props => <Component {...props} />} />;
  }
}

class DynamicLoad extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      component: null
    };
  }

  componentDidMount() {
    if (this.props.load && !this.state.component) {
      this.props.load().then(component => {
        this.setState({
          component: component.default ? component.default : component
        });
      });
    }
  }

  render() {
    if (this.state.component) {
      const Component = this.state.component;
      return <Component />;
    }
    return <div>Loading</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
