import BaseModel from '../../../logic/model/BaseModel';

export default class OutboundPaymentTransactionModal extends BaseModel {
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/transactions';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field',
      },
      {
        name: 'vendorReference',
        label: 'External ID',
        sortable: false,
        type: 'field',
      },
      {
        name: 'merchantName',
        label: 'Note',
        sortable: false,
        type: 'field',
      },
      {
        name: 'amount',
        label: 'Amount',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: 'postDate',
        label: 'Transaction Date',
        sortable: false,
        type: 'field',
      },
    ];
  }
}
