/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import BaseModel from '@launchpad/logic/model/BaseModel';
import {
  FormHandler,
  FormSubmitWrapper,
  InputField,
  ModalDialog,
} from '../../../index';

export default class OTPModal extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new OTPModel({
        publicHash: this.props.publicHash,
        secret: '',
      })
    );
  }

  onSubmitSuccess(data) {
    // this.props.onClose();
    if (this.props.onSubmitSuccess) {
      this.props.onSubmitSuccess(data);
    }
  }

  render() {
    const { formHandler } = this;
    return (
      <ModalDialog
        show={this.props.showModal}
        onClose={() => this.props.onClose()}
      >
        <div>
          <h3 style={{ textAlign: 'center' }}>Authorization request</h3>
          <div
            className="element-with-blur"
            style={{ paddingTop: '2px', marginRight: 20 }}
          >
            <div className="add-new-group" style={{ position: 'relative' }}>
              <div className="container">
                <FormSubmitWrapper
                  formHandler={formHandler}
                  to={this.props.redirectRoute}
                  buttonText={this.props.buttonText}
                  customCloseFunction={this.props.onClose}
                  doNotShowSuccessToast
                >
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Please enter OTP sent to your email"
                    type="text"
                    name="secret"
                    value={this.state.record.secret}
                    handler={formHandler}
                  />
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

class OTPModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/auth/authorization/confirm';
  }
}

OTPModal.defaultProps = {
  entityId: null,
  entity: null,
};
